import React from 'react';

import { Provider, Heading, Subhead, Pre, NavLink, Small,
} from 'rebass'
import {
  Hero, CallToAction, ScrollDownIndicator, Section, Flex, Feature, Testimony

} from 'react-landing-page'

const App = props => (
  <Provider>
    <Hero
      color="black"
      bg="white"
      backgroundImage={process.env.PUBLIC_URL + "/PocketspaceHero.jpg"}
    >
        <Heading style={{
            marginBottom: '15px',
          }}>Pocketspace</Heading>
        <Subhead style={{textAlign: 'center'}}>Let's create a new type of gym that preserves social distancing.</Subhead>
        <CallToAction href="#learn-more" mt={3}>Learn More</CallToAction>
        <ScrollDownIndicator/>
    </Hero>
    <Section
      id="learn-more">
      <Heading
        textAlign="center"
        style={{
          marginBottom: '25px',
        }}
        >Social distancing might last 12+ months</Heading>
      <Flex alignItems="center" justifyContent="space-around">

          <Testimony
              style={{
                maxWidth: '350px',
                marginLeft: '5px',
                marginRight: '5px',
              }}
              authorAvatar={process.env.PUBLIC_URL + "/vox.png"}
              authorTitle=""
              authorName="Vox"
              url="http://www.vox.com/science-and-health/2020/3/17/21181694/coronavirus-covid-19-lockdowns-end-how-long-months-years"
              >
              Researchers say we face a horrible choice: practice social distancing for months or a year, or let hundreds of thousands die.
          </Testimony>

          <Testimony
              style={{
                maxWidth: '300px',
                marginLeft: '5px',
                marginRight: '5px',
              }}
              authorAvatar={process.env.PUBLIC_URL + "/wp.png"}
              authorTitle=""
              authorName="The Washington Post"
              url="https://www.washingtonpost.com/health/2020/03/16/social-distancing-coronavirus/"
              >
              So it’s possible, even most likely, that after U.S. cases peak, Americans will still have to maintain some measures — such as isolating the infected, constant hand-washing, some degree of social distancing — until a viable vaccine is developed, which could take 12 to 18 months.
          </Testimony>

      </Flex>
    </Section>
    <Section
        heading="Let's create a new type of gym that preserves social distancing"
        subhead=''
        width={1}
    >
    </Section>
    <Heading textAlign="center">Here's how we do that</Heading>
    <Flex flexWrap="wrap" justifyContent="center">
        <Feature icon="👋" description="Indicate your support for a Pocketspace in your neighborhood.">
            1. Interest
        </Feature>
        <Feature icon="🔨" description="A kind neighbor converts their garage (takes less than 1 weekend).">
            2. Creation
        </Feature>
        <Feature icon="🏋️" description="Reserve time and gain access via an app (under development as we speak).">
            3. Use
        </Feature>
        <Feature icon="💸" description="Support the kind neighbor with a donation.">
          4. Support
        </Feature>
    </Flex>

    <Section>
      <Subhead style={{
          textAlign: 'center',
          marginBottom: '15px'
        }}>Let's make this happen!</Subhead>
      <CallToAction href='https://pocketspace.typeform.com/to/ccDmso'>Show Your Interest</CallToAction>
    </Section>

    <Flex is="footer" alignItems="center" p={3}>
      <Small color="grey" ml="auto">hello@pocketspace.org</Small>
    </Flex>
  </Provider>
)

export default App;
